import mutations from './mutations';

const state = () => ({
    cct: [],
    administrative_areas:  []
})


const actions = {
    async changeCCT({ commit }, value) {
        try {
            commit('cct', value);
        } catch (err) {
            commit('cct', '');
        }
    },
    async changeAdministrativeAreas({ commit }, value) {
        try {
            commit('administrativeAreas', value);
        } catch (err) {
            commit('administrativeAreas', '');
        }
    },
}

export default {
    state,
    actions,
    mutations
}