const routes = [
    {
      path: '',
      name: 'login',
      component: () => import('@/view/login/index.vue'),
    },
    {
      path: '/home/events/:id',
      name: 'eventDetail',
      component: () =>
          import('@/view/login/index.vue'),
    },
  ];

  export default routes;
