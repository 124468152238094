import { sendRequest } from "../utility/utility";
import { getItem } from "../utility/localStorageControl";

const createEvent = async(data) => {
    const route = "events";
    const token = getItem("token");
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: data
    };
    return await sendRequest(route, request);
}
const editEvent = async(id, data) => {
    const route = "events/"+id;
    const token = getItem("token");
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: data
    };
    return await sendRequest(route, request);
}

const cancelEvent = async (id) => {
    const route = "cancelevent/"+id;
    const token = getItem("token");
    const request = {
        method: "POST",
        headers : {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request);
}

const getMyEvents = async(page, search) => {
    const route = `my-events?page=${page}&search=${search}`;
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            Accept: "form-data",
            'Authorization': `Bearer ${token}`
        }
    };
    return await sendRequest(route, request);
}

const getMyFavoriteEvents = async(page) => {
    const route = `favoriteEvents?page=${page}`;
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            Accept: "form-data",
            'Authorization': `Bearer ${token}`
        }
    };
    return await sendRequest(route, request)
}

const searchFavoriteEvents = async (search) => {
    const route = `searchFavoriteEvents?title=${search}`;
    const token = getItem("token");
    const request = {
        method: "POST",
        headers: {
            Accept: "form-data",
            'Authorization': `Bearer ${token}`
        }
    };
    return await sendRequest(route, request)
}

const getAllEvents = async() => {
    const route = "event/getAll";
    const token_ssid = getItem("ssid");
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            token: `${token_ssid}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getFilesEvent = async(id) => {
    const token_ssid = getItem("ssid");
    const route = "attachment/getInfoByEvent/" + id;
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            token: `${token_ssid}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const generateFileByEvent = async(id) => {
    const token_ssid = getItem("ssid");
    const route = "attachment/get/" + id;
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            token: `${token_ssid}`,
            Accept: "application/json"
        },
        responseType: "blob"
    };
    return await sendRequest(route, request);
}

const getAllEventsByUser = async(id) => {
    const route = "event/getByParticipant/" + id;
    const token_ssid = getItem("ssid");
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            token: `${token_ssid}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getEvent = async(id) => {
    const route = "events/" + id;
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request);
}

const getAdministrativeAreas = async() => {
    const token = getItem("token");
    const route = "administrative_areas";
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getWorkCenter = async() => {
    const token_ssid = getItem("ssid");
    const route = "questions/lvl3/1";
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            token: `${token_ssid}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getEventsOnValidation = async(pageNumber, search = null) => {
    let route = "";
    if (search == null) {
        route = `events/index_validator?page=${pageNumber}`;
    } else {
        route = `events/index_validator?page=${pageNumber}&search=${search}`;
    }
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request);
}

const getDetailsEvent = async(id, showAllSubEvents = false) => {
    const token = getItem("token");
    const route = `events/${id}?showAllSubs=${showAllSubEvents ? 1: 0}`;
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request);
}

const approveEvent = async(id, data) => {
    const token = getItem("token");
    const route = `events/${id}/approve`;
    const request = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: JSON.stringify(data)
    };
    return await sendRequest(route, request);
}

const getFileDetail = async(route) => {
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request, true);
}

const registerToEvent = async(id, data) => {
    const token = getItem("token");
    const route = `register_event/${id}`;
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: data
    };
    return await sendRequest(route, request);
}

const registerToEventWithSubevents = async(id, data) => {
    const token = getItem("token");
    const route = `register_subevent/${id}`;
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: data
    };
    return await sendRequest(route, request);
}

const registerUsersToEvent = async(data) => {
    const token = getItem("token");
    const route = `register_event_cct`;
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: data
    };
    return await sendRequest(route, request);
}

const unregisterToEvent = async(id) => {
    const token = getItem("token");
    const route = `unsubscribe/${id}`;
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getParticipants = async (id, pageNumber, search = null) => {
    let route = "";
    if (search == null) {
        route = `participants/${id}?page=${pageNumber}`;
    } else {
        route = `participants/${id}?page=${pageNumber}&search=${search}`;
    }
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request);
}

const deleteParticipants = async (id, usersId) => {
    
    var formData = new FormData();

    formData.append("usersId", JSON.stringify(usersId));
    for (var value of formData.values()) {
        console.log(value);
     }
    const route = `deleteassistants/${id}`;
    const token = getItem("token");
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: formData,
    };
    return await sendRequest(route, request)
}

const deleteParticipantsBySubevent = async (id, usersId) => {
    
    var formData = new FormData();

    formData.append("usersId", JSON.stringify(usersId));
    for (var value of formData.values()) {
        console.log(value);
     }
    const route = `subevent/deleteassistants/${id}`;
    const token = getItem("token");
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: formData,
    };
    return await sendRequest(route, request)
}

const getDynamicFormAnswers = async (id) => {
    const token = getItem("token");
    const route = `dynamic_forms/answers/${id}`;
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getParticipantsReport = async(id, search = null, isSubevent = false) => {
    let route = "";
    if (search == null) {
        route = isSubevent ?`subevent/participants-report/${id}` : `participants-report/${id}`;
    } else {
        route = isSubevent ? `subevent/participants-report/${id}?search=${search}` : `participants-report/${id}?search=${search}`;
    }
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request, true);
}
const getCarouselImages = async () => {
    const token = getItem("token");
    const route = `geturl`;
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}


const getResponsibleRecordsUser = async () => {
    const token = getItem("token");
    const route = `events/create`;
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const allowEditFormAnswers = async (id, data) => {
    const token = getItem("token");
    const route = `givepermission/${id}`;
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: data
    };
    return await sendRequest(route, request);
}

const editAnswer = async (id, data) => {
    const token = getItem("token");
    const route = `dynamic_forms/answers/${id}`;
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: data
    };
    return await sendRequest(route, request);
}

const getUserByCurp = async (curp, event_id, isSubevent = 0) => {
    const token = getItem("token");
    const route = `events/get-user-by-curp?curp=${curp}&event_id=${event_id}&isSubevent=${isSubevent}`;
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const responsibleEventUser = async (data) => {
    const token = getItem("token");
    const route = `administrative-area/users`;
    const request = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    };
    return await sendRequest(route, request);
}

const getRegisterAttendanceEvents = async (numberPage, search) => {
    const token = getItem("token");
    const route = `events/attendance-record?page=${numberPage}&search=${search}`;
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getSubevent = async(id) => {
    const route = `subevent/${id}`;
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request);
}

const getSubevents = async(id,) => {
    const route = `event/${id}/subevents`;
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request);
}

const getSubeventParticipants = async (id, pageNumber, search = null) => {
    let route = "";
    if (search == null) {
        route = `event/participants/subevent/${id}`;
    } else {
        route = `event/participants/subevent/${id}?page=${pageNumber}&search=${search}`;
    }
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request);
}

const validateRegisterCode = async (id, data) => {
    const token = getItem("token");
    const route = `event/${id}/validateCode`;
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: JSON.stringify(data)
    };
    return await sendRequest(route, request);
}

const getHeadquarters = async () => {
    let route = "get-sedes";
    const token = getItem("token");
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        }
    };
    return await sendRequest(route, request);
}


export {
    createEvent,
    getMyEvents,
    getMyFavoriteEvents,
    searchFavoriteEvents,
    getAllEvents,
    getFilesEvent,
    generateFileByEvent,
    getAllEventsByUser,
    getAdministrativeAreas,
    getWorkCenter,
    getEvent,
    getEventsOnValidation,
    getDetailsEvent,
    approveEvent,
    getFileDetail,
    registerToEvent,
    registerUsersToEvent,
    getDynamicFormAnswers,
    unregisterToEvent,
    getParticipants,
    deleteParticipants,
    getParticipantsReport,
    getCarouselImages,
    getResponsibleRecordsUser,
    editEvent,
    cancelEvent,
    allowEditFormAnswers,
    editAnswer,
    getUserByCurp,
    responsibleEventUser,
    getRegisterAttendanceEvents,
    registerToEventWithSubevents,
    getSubevent,
    getSubevents,
    getSubeventParticipants,
    deleteParticipantsBySubevent,
    validateRegisterCode,
    getHeadquarters
}


