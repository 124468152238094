import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
//import store from '@/vuex/store';

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import('@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: { auth: false },
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

/*router.beforeEach((to, from, next) => {
  // console.log(to, store);
  if (to.meta.auth && store.state.auth.login) {
    next({ to: '/' });
  } else if (!to.meta.auth && !store.state.auth.login) {
    next({ name: 'notFound' });
  } else {
    next();
  }
});*/

export default router;
