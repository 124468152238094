/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */

import {  removeItem, getItem } from './localStorageControl';
import { Notification } from 'ant-design-vue';
import moment from 'moment';
import * as questionTypes from '@/constants/QuestionTypes.js';
import * as Yup from "yup";
const base_url = process.env.VUE_APP_BASE_URL;

const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const isNumber = (evt) => {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
}

const cloneObject = object => {
  return JSON.parse(JSON.stringify(object));
};

const sendRequest = async (route, request, isFile = false) => {
  let response;
  try {
    response = await fetch(base_url + route, request);
    if (response.status === 401) {
      //if (response.status === 401 || response.status == 500 || response.status == 403) {
      notificationponseAndRedirect('error', 'Sesión actual', 'Sesión expirada');
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
  try {
    const data =
      isFile && response.status === 200
        ? await response.blob()
        : await response.json();
    return data;
  } catch (error) {
    return { success: false };
  }
};

const notificationponseAndRedirect = async (type, message, description) => {
  Notification[type]({
    message: message,
    description: description,
  });
  await signOutSsid().then(() => {
    //console.log(response);
  }).catch(() => {
    //console.log(error)
  });
  removeItem('ssid');
  removeItem('userData');
  removeItem('roles');
  removeItem('token');
  setTimeout(() => {
    location.replace("https://mi.sej.jalisco.gob.mx?servicio=" + process.env.VUE_APP_BASE_URL_FRONTEND);
  }, 3000);
};

const signOutSsid = async () => {
  const token_ssid = getItem("ssid");
  const request = {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          auth: `${token_ssid}`,
          Accept: "application/json"
      },
    };
  const route = "access/signOut";
  const response =  await fetch(base_url + route, request)
  return response;
}

const generateUUID = () => {
  let d = new Date().getTime();
  let uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};
const formatDate =  (dateString) => {
  if(!dateString){
    return ''
  }
  const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado',];
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril','Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  const date = new Date(dateString); //Se crea con GMT-0
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000) //Acomodar el offset del horario
  return `${days[date.getDay()]} ${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`

};

const removeAccents = (str) => {
 return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

const timeAgo =  (date, onlyTime=false) => {

  const postDate = moment(date);
  const currentDate = moment(new Date());
  const duration = moment.duration(currentDate.diff(postDate));
  const minutes = (duration.asMinutes() | 0);
  const hours = (duration.asHours() | 0);
  switch (true) {
    case minutes === 0:
      return 'Ahora'
    case minutes < 60:
      return `${minutes} min`;
    case hours < 24:
      return `${hours} horas`;
    default:
      return !onlyTime ?  `${postDate.format("DD-MM-YYYY")} ${postDate.format('hh:mm A')}` : `${postDate.format('hh:mm A')}`
  }
};


const validateShortAnswer = async (answer, validations) => {
  let error = '';
  switch(validations.validationType) {
    case 'limits':
      if (validations.characterType == 'number'){ //Validar número
        if (answer && answer.length && !/^\d*$/.test(answer))
          error = "Ingrese un número válido";
      }
      if (validations.min && answer && answer.length < validations.min) {
        error = `El texto debe tener mínimo ${validations.min} caracteres`;
      }
      if (validations.max && answer && answer.length > validations.max) {
        error = `El texto debe tener máximo ${validations.max} caracteres`;
      }
      if (validations.length && answer && answer.length != validations.length) {
        error = `El texto debe tener exactamente ${validations.length} caracteres`;
      }
      break;
    case 'decimal':
      if (answer && answer.length && !/^\d*(\.\d+)?$/.test(answer))
        error = "Ingrese un número válido";
      break;
    case 'predet':
      if (validations.format == 'email') { //Validar email
        try {
          await Yup.string().email().validate(answer)
        } catch {
          error = "Ingrese un email válido";
        }
      } else { //Validar url
        try {
          await Yup.string().url().validate(answer)
        } catch {
          error = "Ingrese una url válida";
        }
      }
      break;
  }
  return error;
}

const validateQuestion = async (question, answers, errors) => {
  const answer = answers[question.id].content;
  let error = '';
  if (question.validations.required == '1') {
    let valid = true;
    switch(question.type) {
      case questionTypes.cbx:
        valid = answer && answer.filter(value => value).length;
        break;
      case questionTypes.file:
        valid = answer && answer.length;
        break;
      case questionTypes.matrixTxt:
        for (let key in question.matrixValidations) {
          errors[key] = "";
          if (!answer[key]){
            errors[key] = "Este campo es requerido";
            valid = false;
          }
        }
        break;
      default:
        valid = answer;
    }
    error = valid ? "" : "Este campo es requerido";
  }
  if (question.type == questionTypes.shortAnswer) {
    error = error || await validateShortAnswer(answer, question.validations);
  }
  if (question.type == questionTypes.largeAnswer) {
    if (question.validations.min && answer && answer.length < question.validations.min) {
      error = `El texto debe tener mínimo ${question.validations.min} caracteres`;
    }
    if (question.validations.max && answer && answer.length > question.validations.max) {
      error = `El texto debe tener máximo ${question.validations.max} caracteres`;
    }
    if (question.validations.length && answer && answer.length != question.validations.length) {
      error = `El texto debe tener exactamente ${question.validations.length} caracteres`;
    }
  }
  if (question.type == questionTypes.cct) {
    error = errors[question.id] || error;
  }
  if (question.type == questionTypes.matrixTxt) {
    for (let key in question.matrixValidations) {
      errors[key] = errors[key] || await validateShortAnswer(answer[key], question.matrixValidations[key]);
    }
  }
  errors[question.id] = error;
}

const validateDynamicFormSection = async (section, answers) => {
  const errors = {};
  for(let i in section.questions) {
    const question = section.questions[i];
    await validateQuestion(question, answers, errors);
  }
  return errors;
}


const removeSeconds = hour =>{
  if(hour.length>5){
    return hour.slice(0, -3);
  }
  return hour;
};

export {
  ellipsis,
  sendRequest,
  cloneObject,
  isNumber,
  generateUUID,
  formatDate,
  timeAgo,
  validateDynamicFormSection,
  removeAccents,
  removeSeconds
};
