const routes = [{ //RUTA ABSOLUTA
        path: '',
        name: 'initial',
        component: () =>
            import ('@/view/home/index'),
    },
    {
        //MODULO -> INICIO
        path: '/home/',
        component: () =>
            import ('@/view/home/RouterView.vue'),
        children: [{
                path: 'events',
                name: 'home',
                component: () =>
                    import ('@/view/home/index'),
            },
            {
                path: 'register/:mode/:id',
                name: 'register-assistant',
                component: () =>
                    import ('@/view/home/RegistrationUserToEvent'),
            },
            {
                path: 'registers/:mode/:id',
                name: 'register-users',
                component: () =>
                import ('@/view/home/RegistrationsUsersToEvent'),
            },
            {
                path: 'events/:id',
                name: 'eventDetail',
                component: () =>
                    import ('@/view/home/EventDetail.vue'),
            },
            {
                path: 'register/response-qr',
                name: 'responseRegister',
                props: true,
                component: () =>
                    import ('@/view/home/ResponseSuccessQr.vue'),
            },
        ],
    },
    { //MODULO -> MIS EVENTOS
        path: '/my-events/',
        component: () =>
            import ('@/view/events/RouterView.vue'),
        children: [{
                path: 'index',
                name: 'myEvents',
                component: () =>
                    import ('@/view/events/index.vue'),
            },
            {
                path: ':id',
                name: 'myEventsDetail',
                component: () =>
                    import ('@/view/events/EventDetail'),
            },
            {
                path: 'answers/:id/edit',
                name: 'editAnswers',
                component: () =>
                    import('@/view/events/EditAnswers'),
            }
        ],
    },
    {
        path: '/notifications',
        component:()=>import('@/view/notifications/RouterView'),
        children: [
            {
                path: '',
                name: 'myNotifications',
                component: () => import ('@/view/notifications/Notifications'),
            }
        ]

    }
];

export default routes;