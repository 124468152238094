<template>
  <a-locale-provider :locale="config.locale"> 
    <div v-if="isLoading" class="spin">
      <a-spin />
    </div>
    <ThemeProvider
      v-else
      :theme="{
        ...theme,
        rtl,
        topMenu,
        darkMode,
        titleHeader,
      }"
    >
      <Suspense>
        <template #default>
          <router-view></router-view>
        </template>
        <template #fallback>
          <div class="spin">
            <a-spin />
          </div>
        </template>
      </Suspense>
    </ThemeProvider>
  </a-locale-provider>
</template>
<script>
import { ThemeProvider } from 'vue3-styled-components';
import { theme } from './config/theme/themeVariables';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

import es_ES from 'ant-design-vue/lib/locale-provider/es_ES';
import moment from 'moment';
import 'moment/locale/es';
import { getWorkCenter, getAdministrativeAreas } from '@/services/events';
import { getItem } from '@/utility/localStorageControl';

moment.locale('es')

export default {
  name: 'App',
  components: {
    ThemeProvider,
  },
  created() {
    var userData = getItem('userData');
    const { dispatch } = useStore();
    if(userData){
      getWorkCenter()
        .then(response => {
          if(response.success){
            dispatch('changeCCT', response.data);
          }
        })
        .catch(() => {});
      getAdministrativeAreas()
        .then(response => {
          if(response.success){
            dispatch('changeAdministrativeAreas', response.data);
          }
        })
        .catch(() => {});
    }
  },
  setup() {
    const { state } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const isLoading = computed(() => state.themeLayout.loading);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const titleHeader = computed(() => state.themeLayout.titleHeader);

    var config = reactive({
      local: es_ES
    });

    return {
      theme,
      rtl,
      darkMode,
      topMenu,
      isLoading,
      titleHeader,
      config
    };
  },
};
</script>
<style>
@font-face {
  font-family: 'Nutmeg';
  src: url('static/fonts/Nutmeg-RegularItalic.eot');
  src: local('Nutmeg Regular Italic'), local('Nutmeg-RegularItalic'),
    url('static/fonts/Nutmeg-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-RegularItalic.woff2') format('woff2'),
    url('static/fonts/Nutmeg-RegularItalic.woff') format('woff'),
    url('static/fonts/Nutmeg-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('static/fonts/Nutmeg-ThinItalic.eot');
  src: local('Nutmeg Thin Italic'), local('Nutmeg-ThinItalic'),
    url('static/fonts/Nutmeg-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-ThinItalic.woff2') format('woff2'),
    url('static/fonts/Nutmeg-ThinItalic.woff') format('woff'),
    url('static/fonts/Nutmeg-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Ultra';
  src: url('static/fonts/Nutmeg-UltraLight.eot');
  src: local('Nutmeg Ultra Light'), local('Nutmeg-UltraLight'),
    url('static/fonts/Nutmeg-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-UltraLight.woff2') format('woff2'),
    url('static/fonts/Nutmeg-UltraLight.woff') format('woff'),
    url('static/fonts/Nutmeg-UltraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('static/fonts/Nutmeg-Light.eot');
  src: local('Nutmeg Light'), local('Nutmeg-Light'),
    url('static/fonts/Nutmeg-Light.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-Light.woff2') format('woff2'),
    url('static/fonts/Nutmeg-Light.woff') format('woff'),
    url('static/fonts/Nutmeg-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Book';
  src: url('static/fonts/Nutmeg-Book.eot');
  src: local('Nutmeg Book'), local('Nutmeg-Book'),
    url('static/fonts/Nutmeg-Book.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-Book.woff2') format('woff2'),
    url('static/fonts/Nutmeg-Book.woff') format('woff'),
    url('static/fonts/Nutmeg-Book.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('static/fonts/Nutmeg-BlackItalic.eot');
  src: local('Nutmeg Black Italic'), local('Nutmeg-BlackItalic'),
    url('static/fonts/Nutmeg-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-BlackItalic.woff2') format('woff2'),
    url('static/fonts/Nutmeg-BlackItalic.woff') format('woff'),
    url('static/fonts/Nutmeg-BlackItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Ultra';
  src: url('static/fonts/Nutmeg-UltraBlackItalic.eot');
  src: local('Nutmeg Ultra Black Italic'), local('Nutmeg-UltraBlackItalic'),
    url('static/fonts/Nutmeg-UltraBlackItalic.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-UltraBlackItalic.woff2') format('woff2'),
    url('static/fonts/Nutmeg-UltraBlackItalic.woff') format('woff'),
    url('static/fonts/Nutmeg-UltraBlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('static/fonts/Nutmeg-Thin.eot');
  src: local('Nutmeg Thin'), local('Nutmeg-Thin'),
    url('static/fonts/Nutmeg-Thin.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-Thin.woff2') format('woff2'),
    url('static/fonts/Nutmeg-Thin.woff') format('woff'),
    url('static/fonts/Nutmeg-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Extra';
  src: url('static/fonts/Nutmeg-ExtraBoldItalic.eot');
  src: local('Nutmeg Extra Bold Italic'), local('Nutmeg-ExtraBoldItalic'),
    url('static/fonts/Nutmeg-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-ExtraBoldItalic.woff2') format('woff2'),
    url('static/fonts/Nutmeg-ExtraBoldItalic.woff') format('woff'),
    url('static/fonts/Nutmeg-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('static/fonts/Nutmeg-LightItalic.eot');
  src: local('Nutmeg Light Italic'), local('Nutmeg-LightItalic'),
    url('static/fonts/Nutmeg-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-LightItalic.woff2') format('woff2'),
    url('static/fonts/Nutmeg-LightItalic.woff') format('woff'),
    url('static/fonts/Nutmeg-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('static/fonts/Nutmeg-BoldItalic.eot');
  src: local('Nutmeg Bold Italic'), local('Nutmeg-BoldItalic'),
    url('static/fonts/Nutmeg-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-BoldItalic.woff2') format('woff2'),
    url('static/fonts/Nutmeg-BoldItalic.woff') format('woff'),
    url('static/fonts/Nutmeg-BoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('static/fonts/Nutmeg-Black.eot');
  src: local('Nutmeg Black'), local('Nutmeg-Black'),
    url('static/fonts/Nutmeg-Black.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-Black.woff2') format('woff2'),
    url('static/fonts/Nutmeg-Black.woff') format('woff'),
    url('static/fonts/Nutmeg-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Bold';
  src: url('static/fonts/Nutmeg-Bold.eot');
  src: local('Nutmeg Bold'), local('Nutmeg-Bold'),
    url('static/fonts/Nutmeg-Bold.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-Bold.woff2') format('woff2'),
    url('static/fonts/Nutmeg-Bold.woff') format('woff'),
    url('static/fonts/Nutmeg-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Ultra';
  src: url('static/fonts/Nutmeg-UltraLightItalic.eot');
  src: local('Nutmeg Ultra Light Italic'), local('Nutmeg-UltraLightItalic'),
    url('static/fonts/Nutmeg-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-UltraLightItalic.woff2') format('woff2'),
    url('static/fonts/Nutmeg-UltraLightItalic.woff') format('woff'),
    url('static/fonts/Nutmeg-UltraLightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Extra';
  src: url('static/fonts/Nutmeg-ExtraBold.eot');
  src: local('Nutmeg Extra Bold'), local('Nutmeg-ExtraBold'),
    url('static/fonts/Nutmeg-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-ExtraBold.woff2') format('woff2'),
    url('static/fonts/Nutmeg-ExtraBold.woff') format('woff'),
    url('static/fonts/Nutmeg-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Book';
  src: url('static/fonts/Nutmeg-BookItalic.eot');
  src: local('Nutmeg Book Italic'), local('Nutmeg-BookItalic'),
    url('static/fonts/Nutmeg-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-BookItalic.woff2') format('woff2'),
    url('static/fonts/Nutmeg-BookItalic.woff') format('woff'),
    url('static/fonts/Nutmeg-BookItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Regular';
  src: url('static/fonts/Nutmeg-Regular.eot');
  src: local('Nutmeg Regular'), local('Nutmeg-Regular'),
    url('static/fonts/Nutmeg-Regular.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-Regular.woff2') format('woff2'),
    url('static/fonts/Nutmeg-Regular.woff') format('woff'),
    url('static/fonts/Nutmeg-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Ultra';
  src: url('static/fonts/Nutmeg-UltraBlack.eot');
  src: local('Nutmeg Ultra Black'), local('Nutmeg-UltraBlack'),
    url('static/fonts/Nutmeg-UltraBlack.eot?#iefix') format('embedded-opentype'),
    url('static/fonts/Nutmeg-UltraBlack.woff2') format('woff2'),
    url('static/fonts/Nutmeg-UltraBlack.woff') format('woff'),
    url('static/fonts/Nutmeg-UltraBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Nutmeg Headline BookItalic';
  src: url('static/fonts/NutmegHeadline-BookItalic.otf');
  src: local('Nutmeg Headline Book Italic'), local('Nutmeg-Headline-BookItalic'),
    url('static/fonts/NutmegHeadline-BookItalic.otf?#iefix') format('opentype');
}
@font-face {
  font-family: 'Nutmeg Headline ExtraBold';
  src: url('static/fonts/NutmegHeadline-ExtraBold.otf');
  src: local('Nutmeg Headline Extra Bold'), local('Nutmeg-Headline-ExtraBold'),
    url('static/fonts/NutmegHeadline-ExtraBold.otf?#iefix') format('opentype');
}
@font-face {
  font-family: 'Nutmeg Headline UltraBlack';
  src: url('static/fonts/NutmegHeadline-UltraBlack.otf');
  src: local('Nutmeg Headline Ultra Black'), local('Nutmeg-Headline-UltraBlack'),
    url('static/fonts/NutmegHeadline-UltraBlack.otf?#iefix') format('opentype');
}
@font-face {
  font-family: 'Nutmeg Black';
  src: url('static/fonts/Nutmeg-Black.otf');
  src: local('Nutmeg Black'), local('Nutmeg-Black'),
    url('static/fonts/Nutmeg-Black.otf?#iefix') format('opentype');
}
@font-face {
  font-family: "Avenir Heavy";
  src: local("static/fonts/Avenir-Heavy.ttf"),
  url('static/fonts/Avenir-Heavy.ttf') format("truetype");
}
@font-face {
  font-family: "Avenir Roman";
  src: local("static/fonts/AvenirLTStd-Roman.otf"),
  url('static/fonts/AvenirLTStd-Roman.otf') format("opentype");
}
@font-face {
  font-family: "Avenir Black";
  src: local("static/fonts/AvenirLTStd-Black.otf"),
  url('static/fonts/AvenirLTStd-Black.otf') format("opentype");
}
@font-face {
  font-family: "Avenir Book";
  src: local("static/fonts/AvenirLTStd-Book.otf"),
  url('static/fonts/AvenirLTStd-Book.otf') format("opentype");
}

.title-normal{
  font-family: 'Nutmeg Regular';
}
.title-bold-normal{
  font-family: 'Nutmeg Regular';
  font-weight: 600;
}

.title-bold-book{
  font-family: 'Nutmeg Book';
  font-weight: bold;
}
.title-book{
  font-family: 'Nutmeg Book';
}

.title-extra{
  font-family: 'Nutmeg Extra';
}
.title-bold-extra{
  font-family: 'Nutmeg Extra';
  font-weight: bold;
}

.title-ultra{
  font-family: 'Nutmeg Book';
}
.title-bold-ultra{
  font-family:' Nutmeg Ultra';
  font-weight: bold;
}

.title_color-light{
  color: #636668;
}
.title_color-dark{
  color: #212529;
}
.title_color-secondary{
  color: #E70E4C;
}
.f-w-800{
  font-weight: 800;
}
.justify-center{
  justify-content: center;
}
.mt-1{
  margin-top: .25rem !important;
}
.ml-1{
  margin-left: .25rem !important;
}
.mr-1{
  margin-right: .25rem !important;
}
.mb-1{
  margin-bottom: .25rem !important;
}
.mt-2{
  margin-top: .50rem !important;
}
.ml-2{
  margin-left: .50rem !important;
}
.mr-2{
  margin-right: .50rem !important;
}
.mb-2{
  margin-bottom: .50rem !important;
}
.mt-3{
  margin-top: 1rem !important;
}
.ml-3{
  margin-left: 1rem !important;
}
.mr-3{
  margin-right: 1rem !important;
}
.mb-3{
  margin-bottom: 1rem !important;
}
.mt-4{
  margin-top: 2rem !important;
}
.ml-4{
  margin-left: 2rem !important;
}
.mr-4{
  margin-right: 2rem !important;
}
.mb-4{
  margin-bottom: 2rem !important;
}

.pt-1{
  padding-top: .25rem !important;
}
.pl-1{
  padding-left: .25rem !important;
}
.pr-1{
  padding-right: .25rem !important;
}
.pb-1{
  padding-bottom: .25rem !important;
}
.pt-2{
  padding-top: .50rem !important;
}
.pl-2{
  padding-left: .50rem !important;
}
.pr-2{
  padding-right: .50rem !important;
}
.pb-2{
  padding-bottom: .50rem !important;
}
.pt-3{
  padding-top: 1rem !important;
}
.pl-3{
  padding-left: 1rem !important;
}
.pr-3{
  padding-right: 1rem !important;
}
.pb-3{
  padding-bottom: 1rem !important;
}
.pt-4{
  padding-top: 2rem !important;
}
.pl-4{
  padding-left: 2rem !important;
}
.pr-4{
  padding-right: 2rem !important;
}
.pb-4{
  padding-bottom: 2rem !important;
}

.no-events-label{
  font-size: 16px;
  font-family: 'Avenir Book';
}

#sDash_validation-form_filter > div > input{
  color: #FFFFFF !important;
  background-color: #3FA7E1 !important;
}

</style>
