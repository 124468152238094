
export const shortAnswer = 'shortAnswer'
export const multipleChoice = 'multipleChoice'
export const largeAnswer = 'largeAnswer'
export const cbx = 'cbx'
export const dropDown = 'dropDown'
export const date = 'date'
export const time = 'time'
export const file = 'file'
export const matrixTxt = 'matrixTxt'
export const cct = 'cct'
export const cct_fill = 'cct_fill'
