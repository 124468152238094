const routes = [{
        //RUTA ABSOLUTA
        path: '',
        name: 'initial',
        component: () =>
            import ('@/view/home/index'),
    },
    {
        //MODULO -> INICIO
        path: '/home/',
        component: () =>
            import ('@/view/home/RouterView.vue'),
        children: [{
                path: 'events',
                name: 'home',
                component: () =>
                    import ('@/view/home/index'),
            },
            {
                path: 'register/:mode/:id',
                name: 'register-assistant',
                component: () =>
                    import ('@/view/home/RegistrationUserToEvent'),
            },
            {
                path: 'registers/:mode/:id',
                name: 'register-users',
                component: () =>
                import ('@/view/home/RegistrationsUsersToEvent'),
            },
            {
                path: 'events/:id',
                name: 'eventDetail',
                component: () =>
                    import ('@/view/home/EventDetail.vue'),
            },
            {
                path: 'register/response-qr',
                name: 'responseRegister',
                props: true,
                component: () =>
                    import ('@/view/home/ResponseSuccessQr.vue'),
            },
        ],
    },
    {
        //MODULO -> MIS EVENTOS
        path: '/my-events/',
        component: () =>
            import ('@/view/events/RouterView.vue'),
        children: [{
                path: 'index',
                name: 'myEvents',
                component: () =>
                    import ('@/view/events/index.vue'),
            },
            {
                path: ':id',
                name: 'myEventsDetail',
                component: () =>
                    import ('@/view/events/EventDetail'),
            },
            {
                path: 'participants/answers/:id',
                name: 'answers',
                component: () =>
                    import ('@/view/events/Answers'),
            },
            {
                path: 'participants/:id',
                name: 'participants',
                component: () =>
                    import ('@/view/events/Participants'),
            },
            {
                path: 'subevents/:id',
                name: 'subevents',
                component: () =>
                    import ('@/view/events/Subevents'),
            },
            {
                path: 'subevents/:id/:subeventId',
                name: 'subeventParticipants',
                component: () =>
                    import ('@/view/events/SubeventParticipants'),
            },
            {
                path: 'answers/:id/edit',
                name: 'editAnswers',
                component: () =>
                    import('@/view/events/EditAnswers'),
            },
            {
                path: 'edit/:id/notvalid',
                name: 'editNotValidEvent',
                component: () =>
                  import ('@/view/create-event/EditNoValidEvent.vue'),
            },
            {
                path: 'edit/:id/valid',
                name: 'editValidEvent',
                component: () =>
                  import ('@/view/create-event/EditValidEvent.vue'),
            },
        ],
    },
    { //MODULO -> VALIDACION DE EVENTOS
        path: '/validation/',
        component: () =>
            import ('@/view/validation-events/RouterView.vue'),
        children: [{
                path: 'index',
                name: 'validation',
                component: () =>
                    import ('@/view/validation-events/index.vue'),
            },
            {
                path: ':id',
                name: 'validationDetails',
                component: () =>
                    import ('@/view/validation-events/details.vue'),
                props: true
            }
        ],
    },
    { //MODULO -> CREAR EVENTOS
        path: '/events/',
        component: () =>
            import ('@/view/create-event/RouterView.vue'),
        children: [{
            path: 'create',
            name: 'createEvent',
            component: () =>
                import ('@/view/create-event/create.vue'),
        },

        ],
    },
    {
        path: '/notifications',
        component:()=>import('@/view/notifications/RouterView'),
        children: [
            {
                path: '',
                name: 'myNotifications',
                component: () => import ('@/view/notifications/Notifications'),
            }
        ]

    }
];

export default routes;