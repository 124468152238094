const routes = [{ //RUTA ABSOLUTA
    path: '',
    name: 'initial',
    component: () =>
        import ('@/view/home/index'),
},
{
    //MODULO -> INICIO
    path: '/home/',
    component: () =>
        import ('@/view/home/RouterView.vue'),
    children: [{
            path: 'events',
            name: 'home',
            component: () =>
                import ('@/view/home/index'),
        },
        {
            path: 'register/:mode/:id',
            name: 'register-assistant',
            component: () =>
                import ('@/view/home/RegistrationUserToEvent'),
        },
        {
            path: 'registers/:mode/:id',
            name: 'register-users',
            component: () =>
            import ('@/view/home/RegistrationsUsersToEvent'),
        },
        {
            path: 'events/:id',
            name: 'eventDetail',
            component: () =>
                import ('@/view/home/EventDetail.vue'),
        },
        {
            path: 'register/response-qr',
            name: 'responseRegister',
            props: true,
            component: () =>
                import ('@/view/home/ResponseSuccessQr.vue'),
        },
    ],
},
{ //MODULO -> MIS EVENTOS
    path: '/my-events/',
    component: () =>
        import ('@/view/events/RouterView.vue'),
    children: [
        {
            path: 'index',
            name: 'myEvents',
            component: () =>
                import ('@/view/events/index.vue'),
        },
        {
            path: ':id',
            name: 'myEventsDetail',
            component: () =>
                import ('@/view/events/EventDetail'),
        },
        {
            path: 'answers/:id/edit',
            name: 'editAnswers',
            component: () =>
                import('@/view/events/EditAnswers'),
        }
    ],
},
{//MODULO -> REGISTRO DE ASISTENCIA
    path: '/register-assistance/',
    component: () =>
        import ('@/view/register-assistance/RouterView.vue'),
    children: [
        {
            path: ':id',
            name: 'registerAssistant',
            component: () =>
                import ('@/view/register-assistance/index'),
        },
        {
            path: 'list/:id',
            name: 'listAssistant',
            component: () =>
                import ('@/view/register-assistance/listAssistance'),
        },
        {
            path: 'subevents/:id',
            name: 'listSubevents',
            component: () =>
                import ('@/view/register-assistance/listSubevents'),
        },
        {
            path: 'subevent/assistance/:id/:idEvent',
            name: 'listAsistanceSubevents',
            component: () =>
                import ('@/view/register-assistance/listAssistanceSubevent'),
        },
    ],
},
    {
        path: '/notifications',
        component:()=>import('@/view/notifications/RouterView'),
        children: [
            {
                path: '',
                name: 'myNotifications',
                component: () => import ('@/view/notifications/Notifications'),
            }
        ]

    }
];

export default routes;