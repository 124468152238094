import AdministradorGeneralRoutes from './routesRoles/AdministradorGeneral';
import AdministradorAreaRoutes from './routesRoles/AdministradorArea';
import DirectorCCTRoutes from './routesRoles/DirectorCCT';
import ParticipanteRoutes from './routesRoles/Participante';
import ResponsableDeEventoRoutes from './routesRoles/ResponsableDeEvento';
import ResponsableDeRegistroRoutes from './routesRoles/ResponsableDeRegistro';
import DefaultRoutes from './routesRoles/DefaultRoutes';
import { getItem } from '../utility/localStorageControl';

var routes = [];
const userStorage = getItem('userData');
const rolesUserStorage = getItem('roles');

if (userStorage != undefined && rolesUserStorage != undefined) {
    rolesUserStorage.forEach(role => {
        if (role.permiso == "activo") {
            switch (role.nombre) {
                case "Administrador general":
                case "Administrador General":
                    addRoutesToRouter(AdministradorGeneralRoutes);
                    break;
                case "Administrador de Área":
                case "Administrador de Area":
                case "Administrador de area":
                    addRoutesToRouter(AdministradorAreaRoutes);
                    break;
                case "Director de CCT":
                    addRoutesToRouter(DirectorCCTRoutes);
                    break;
                case "Responsable de evento":
                case "Responsable de Evento":
                    addRoutesToRouter(ResponsableDeEventoRoutes);
                    break;
                case "Participante":
                    addRoutesToRouter(ParticipanteRoutes);
                    break;
                case "Responsable de Registro":
                case "Responsable de registro":
                    addRoutesToRouter(ResponsableDeRegistroRoutes);
                break;
                default:
                    break;
            }
        }
    })
    addNotFoudRoute()
} else {
    routes = DefaultRoutes;
    addNotFoudRoute()
}


function addRoutesToRouter(roleRoutes) {
    roleRoutes.forEach(route => {
        if(route.path!="/notifications"
          || (route.path=="/notifications" && !routes.find(item=>item.path=="/notifications"))){
            routes.push(route);
        }
        /*if(!routes.includes(route)){
            routes.push(route);
        }*/
    });
    //console.log(routes)
}

function addNotFoudRoute() {
    routes.push({
        path: '/:pathMatch(.*)*',
        name: "notFound",
        component: () =>
            import ('@/view/pages/404.vue'),
    })
}

export default routes;